import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ClickAwayListener,
  Popper,
  MenuItem,
  MenuList,
  Button as ButtonMUI,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import Popup from "../Popup/Popup";
import styles from "./message-list.module.css";
import Box from "../Box/Box";
import Button from "../Button/Button";

const TYPES = {
  MESSAGES: "messages",
  SUMMARY: "summary",
};

const propertiesSummary = {
  Нейтральный: {
    text: "Самые обсуждаемые нейтральные темы с конкретикой",
    property: "topicsNeutral",
    color: "#F9C74F",
  },
  Позитивный: {
    text: "Самые обсуждаемые позитивные темы с конкретикой",
    property: "topicsPositive",
    color: "#90be6d",
  },
  Негативный: {
    text: "Самые обсуждаемые негативные темы (главные проблемы дома) с конкретикой ",
    property: "topicsNegative",
    color: "#F94144",
  },
};
const MessageList = ({
  messages,
  isOpen,
  setPopupClose,
  senderFilter,
  emotionFilter,
  typeFilter,
  setSenderFilter,
  setEmotionFilter,
  setTypeFilter,
  currentReport,
}) => {
  const [anchorElSender, setAnchorElSender] = useState(null);
  const [anchorElEmotion, setAnchorElEmotion] = useState(null);
  const [anchorElType, setAnchorElType] = useState(null);
  const [uniqueSenders, setUniqueSenders] = useState([]);
  const [uniqueEmotions, setUniqueEmotions] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [viewMode, setViewMode] = useState(TYPES.MESSAGES);

  useEffect(() => {
    const senders = [...new Set(messages.map((msg) => msg.senderName))].sort(
      (a, b) => a.localeCompare(b)
    );
    const emotions = [...new Set(messages.map((msg) => msg.emotionMessage))];
    const types = [...new Set(messages.map((msg) => msg.typeMessage))];
    setUniqueSenders(senders);
    setUniqueEmotions(emotions);
    setUniqueTypes(types);
  }, [messages]);

  const handleSenderClick = (event) => {
    setAnchorElSender(anchorElSender ? null : event.currentTarget);
    handleCloseOtherPopups("sender");
  };

  const handleEmotionClick = (event) => {
    setAnchorElEmotion(anchorElEmotion ? null : event.currentTarget);
    handleCloseOtherPopups("emotion");
  };

  const handleTypeClick = (event) => {
    setAnchorElType(anchorElType ? null : event.currentTarget);
    handleCloseOtherPopups("type");
  };

  const handleClose = () => {
    setAnchorElSender(null);
    setAnchorElEmotion(null);
    setAnchorElType(null);
    setViewMode(TYPES.MESSAGES);
  };

  const handleCloseOtherPopups = (current) => {
    if (current !== "sender") setAnchorElSender(null);
    if (current !== "emotion") setAnchorElEmotion(null);
    if (current !== "type") setAnchorElType(null);
  };

  const clearFilters = () => {
    setSenderFilter("");
    setEmotionFilter("");
    setTypeFilter("");
    handleClose();
  };

  const applySenderFilter = (value) => {
    setSenderFilter(value !== senderFilter ? value : "");
    handleClose();
  };

  const applyEmotionFilter = (value) => {
    setEmotionFilter(value !== emotionFilter ? value : "");
    handleClose();
  };

  const applyTypeFilter = (value) => {
    setTypeFilter(value !== typeFilter ? value : "");
    handleClose();
  };

  const filteredSortedMessages = useMemo(() => {
    return [...messages]
      .filter((message) => {
        const matchesSender = senderFilter
          ? message.senderName === senderFilter
          : true;
        const matchesEmotion = emotionFilter
          ? message.emotionMessage === emotionFilter
          : true;
        const matchesType = typeFilter
          ? message.typeMessage === typeFilter
          : true;
        return matchesSender && matchesEmotion && matchesType;
      })
      .sort((a, b) => new Date(a.time) - new Date(b.time));
  }, [messages, senderFilter, emotionFilter, typeFilter]);

  useEffect(() => {
    if (!isOpen) clearFilters();
  }, [isOpen]);

  const renderFilterIcon = (filter) => {
    return filter ? (
      <FilterAltIcon className={styles.titleFilterIcon} />
    ) : (
      <FilterListIcon className={styles.titleFilterIcon} />
    );
  };
  const boxData = React.useMemo(
    () => ({
      type: "GridTexts",
      content: currentReport?.[propertiesSummary[emotionFilter]?.property]?.map(
        (t) => ({
          title: t.short,
          text: t.detailed,
          color: propertiesSummary[emotionFilter].color,
        })
      ),
    }),
    [currentReport, emotionFilter]
  );
  return (
    <Popup
      isOpen={isOpen}
      setPopupOpened={setPopupClose}
      title={
        viewMode === TYPES.MESSAGES
          ? `Список сообщений (${filteredSortedMessages.length})`
          : propertiesSummary[emotionFilter]?.text ?? "Сводка"
      }
      className={styles.popupContainer}
    >
      {emotionFilter && (
        <div className={styles.buttons}>
          <Button
            type={viewMode === TYPES.MESSAGES ? "primary" : "secondary"}
            selector="button"
            text="Сообщения"
            action={() => setViewMode(TYPES.MESSAGES)}
            icon={ChatIcon}
          />

          <Button
            type={viewMode === TYPES.SUMMARY ? "primary" : "secondary"}
            selector="button"
            text="Сводка"
            action={() => setViewMode(TYPES.SUMMARY)}
            icon={ListIcon}
          />
        </div>
      )}
      {viewMode === TYPES.SUMMARY ? (
        emotionFilter && <Box {...boxData} />
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "80vh", overflow: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Время</TableCell>
                  <TableCell
                    onClick={handleSenderClick}
                    style={{ cursor: "pointer" }}
                  >
                    Автор {renderFilterIcon(senderFilter)}
                  </TableCell>
                  <TableCell style={{ width: "60%" }}>Сообщение </TableCell>
                  <TableCell
                    onClick={handleEmotionClick}
                    style={{ cursor: "pointer" }}
                  >
                    Эмоция {renderFilterIcon(emotionFilter)}
                  </TableCell>
                  <TableCell
                    onClick={handleTypeClick}
                    style={{ cursor: "pointer" }}
                  >
                    Тип {renderFilterIcon(typeFilter)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSortedMessages.map((message, index) => (
                  <TableRow key={message.id + index}>
                    <TableCell>
                      {new Date(message.time).toLocaleString()}
                    </TableCell>
                    <TableCell>{message.senderName}</TableCell>
                    <TableCell>{message.body}</TableCell>
                    <TableCell>{message.emotionMessage || "-"}</TableCell>
                    <TableCell>{message.typeMessage || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Popper
            open={Boolean(anchorElSender)}
            anchorEl={anchorElSender}
            placement="bottom-start"
          >
            <div className={styles.popperList}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {uniqueSenders.map((sender, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => applySenderFilter(sender)}
                      style={
                        senderFilter === sender ? { fontWeight: "bold" } : {}
                      }
                    >
                      {sender}
                    </MenuItem>
                  ))}
                  {senderFilter && (
                    <MenuItem
                      style={{ fontStyle: "italic" }}
                      onClick={clearFilters}
                    >
                      Сбросить фильтр
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Popper>
          <Popper
            open={Boolean(anchorElEmotion)}
            anchorEl={anchorElEmotion}
            placement="bottom-start"
          >
            <div className={styles.popperList}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {uniqueEmotions.map((emotion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => applyEmotionFilter(emotion)}
                      style={
                        emotionFilter === emotion ? { fontWeight: "bold" } : {}
                      }
                    >
                      {emotion}
                    </MenuItem>
                  ))}
                  {emotionFilter && (
                    <MenuItem
                      style={{ fontStyle: "italic" }}
                      onClick={clearFilters}
                    >
                      Сбросить фильтр
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Popper>
          <Popper
            open={Boolean(anchorElType)}
            anchorEl={anchorElType}
            placement="bottom-start"
          >
            <div className={styles.popperList}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {uniqueTypes.map((type, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => applyTypeFilter(type)}
                      style={typeFilter === type ? { fontWeight: "bold" } : {}}
                    >
                      {type}
                    </MenuItem>
                  ))}
                  {typeFilter && (
                    <MenuItem
                      style={{ fontStyle: "italic" }}
                      onClick={clearFilters}
                    >
                      Сбросить фильтр
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Popper>
          <ButtonMUI
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={clearFilters}
            style={{ marginTop: "16px" }}
            disabled={!senderFilter && !emotionFilter && !typeFilter}
          >
            Сбросить фильтр
          </ButtonMUI>{" "}
        </>
      )}
    </Popup>
  );
};

export default MessageList;
